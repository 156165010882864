h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
p,
span,
button,
input,
div,
li {
  font-family: "Poppins", sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
}
.options > p {
  color: gray;
  margin: 0;
  margin-bottom: 1rem;
  font-size: 17px;
}
.options > button {
  background: blue;
  color: white;
  border: none;
  padding: 0.9rem;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.user-options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.user-options > button {
  background: blue;
  color: white;
  border: none;
  padding: 0.9rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 6px;
}
.svg-icons {
  width: 22px;
  height: auto;
}
.background {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
}
.image-container {
  height: 20rem;
}
.image-container > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* background: currentColor; */
}
@media (max-width: 768px) {
  .image-container {
    height: 14rem;
  }
}
.close-icon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
}
.close-icon > svg {
  cursor: pointer;
  width: 24px;
  height: auto;
  color: red;
}
.user-list {
  background: ghostwhite;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px;
  border: 1px solid dimgrey;
  border-radius: 6px;
  margin-bottom: 12px;
}
.berger-menu {
  position: absolute;
  margin-top: 27px;
  right: 10%;
  background-color: rgb(217, 34, 103);
  border-radius: 50%;
  padding: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .berger-menu {
    left: 17px;
    top: 4rem;
    right: unset;
  }
}
.video-box {
  height: 15rem;
  width: 18rem;
  /* background: gainsboro; */
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  margin: 1rem;
  text-align: center;
}
.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 25px;
}
.option-container > section {
  display: flex;
  gap: 1rem;
  margin: 0.1rem 1rem;
}
.option-container-svg {
  width: 32px;
  height: auto;
  border: 1px solid gainsboro;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}
.mic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid gray;
  padding: 0.5rem;
  border-radius: 37px;
  rotate: 270deg;
  position: absolute;
  left: -53px;
  bottom: 104px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.com-d-name {
  position: absolute;
  margin: 0;
  bottom: 40px;
  left: 47px;
  background: brown;
  color: white;
  padding: 9px;
  border-radius: 5px;
}
.detector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17rem;
  position: relative;
  margin-bottom: 1rem;
}
.not-permit {
  background: black;
  /* color: white; */
  height: 88%;
  padding-top: 13px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detector-mic {
  width: 25px;
  height: auto;
  rotate: 90deg;
}
.mic-warning {
  position: absolute;
  bottom: -32px;
  font-weight: 500;
  font-size: 13px;
}
.permission-alert {
  position: relative;
  display: flex;
  padding: 1rem;
  gap: 1rem;
}
.permission-alert-svg {
  width: 24px;
  height: auto;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.btn {
  background: white;
  color: blue;
  font-size: 13px;
  border-radius: 35px;
  padding: 9px 14px;
  font-weight: bold;
  border: 1px solid blue;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.btn > svg {
  animation: boxsizing 2s infinite;
  width: 27px;
  height: auto;
}

@keyframes boxsizing {
  0% {
    transform: translatey(3px);
  }
  50% {
    transform: translatey(-3px);
  }
  100% {
    transform: translatey(3px);
  }
}
.oc-response {
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .detector {
    flex-wrap: wrap;
  }
  .video-box {
    width: 100%;
  }
  .mic-container {
    rotate: 0deg;
    bottom: 220px;
    left: -52px;
  }
  .detector-mic {
    rotate: 0deg;
  }
  .mic-warning {
    bottom: 186px;
  }
  .oc-response {
    margin-top: 6rem;
    z-index: 100;
  }
  .option-container > section {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .permission-alert {
    flex-wrap: wrap;
  }
}
.meeting-img {
  width: 160px;
  height: auto;
}
.per-con {
  display: flex;
  flex-direction: column;
  padding: 13px;
  gap: 1rem;
  color: gray;
}
.per-content > li::marker {
  color: brown;
  font-size: x-large;
}
.app-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
